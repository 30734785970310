<script setup lang="ts">
import OzContainedButton, { OzContainedButtonSizePreset } from '@@/library/v4/components/OzContainedButton.vue'
import { useWindowSizeStore } from '@@/pinia/window_size'
import { storeToRefs } from 'pinia'

const props = withDefaults(
  defineProps<{
    contentElementSelector?: string
  }>(),
  {
    contentElementSelector: '#main-content',
  },
)

const windowSizeStore = useWindowSizeStore()
const { isSmallerThanTabletLandscape } = storeToRefs(windowSizeStore)

const onClick = (event: MouseEvent): boolean => {
  event.preventDefault()
  const contentElement = document.querySelector(props.contentElementSelector) as HTMLElement
  if (contentElement != null) contentElement.focus()
  return false
}
</script>

<template>
  <OzContainedButton
    :class="[
      // We only apply the `sr-only` class when the element is not focused.
      // We want to avoid `not-sr-only` because it applies some styles that are not desired.
      '[&:not(:focus-visible)]:sr-only',
      'absolute',
      'z-global-snackbar',
      'top-4',
      'start-1/2',
      '-translate-x-1/2',
      'rtl:translate-x-1/2',
    ]"
    :href="contentElementSelector"
    :size-preset="isSmallerThanTabletLandscape ? OzContainedButtonSizePreset.H24px : OzContainedButtonSizePreset.H32px"
    :text="__('Skip to content')"
    @click="onClick"
  />
</template>
